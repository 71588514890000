import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'notfound',
      path: '/:pathMatch(.*)*',
      component: () => import('@/pages/NotFound.vue')
    },

    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/Home.vue'),
      meta: {
        layout: 'basic'
      }
    },
    {
      path: '/accommodations',
      name: 'accommodations',
      component: () => import('@/pages/Accommodations.vue'),
      meta: {
        layout: 'basic'
      }
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import('@/pages/Gallery.vue'),
      meta: {
        layout: 'basic'
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/pages/About.vue'),
      meta: {
        layout: 'basic'
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/pages/Contact.vue'),
      meta: {
        layout: 'basic'
      }
    },

    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/frags/Terms.vue')
    },

    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/frags/Privacy.vue')
    }
  ]
})

export default router
