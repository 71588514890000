<template>
  <div class="root" :class="route.name">
    <nav>
      <RouterLink class="link home" :to="`/`">Home</RouterLink>
      <RouterLink class="link" :to="`/accommodations`"
        >Accommodations</RouterLink
      >
      <RouterLink class="link" :to="`/gallery`">Gallery</RouterLink>
      <RouterLink class="link" :to="`/about`">About</RouterLink>
      <RouterLink class="link" :to="`/contact`">Contact Us</RouterLink>

      <SocialStrip class="social" :size="20" />
    </nav>

    <div class="content">
      <slot />
    </div>

    <!-- <div class="watermark" v-if="!store.isPro">
      <div class="watermark_text">
        Powered by &nbsp;<RouterLink
          class="fcp"
          to="https://www.foodcostprofiler.com"
          target="_blank"
          >Food Cost Profiler</RouterLink
        >
      </div>
      <div>Watermark not shown for a Pro venue</div>
    </div> -->

    <button type="button" class="book pulse" @click="handleBooking">
      Book Now
    </button>

    <div class="location">
      <div class="contact_c">
        <div class="address line" v-html="address"></div>

        <div class="phone line" v-if="phone">
          <a :href="'tel:' + phone">{{ phone }}</a>
        </div>

        <div class="email line" v-if="email">
          <a :href="'mailto:' + email">{{ email }}</a>
        </div>

        <SocialStrip class="social" :size="20" color="grey" />

        <div class="docs_c">
          <router-link class="nav" to="/terms" tag="button" type="button"
            >Terms</router-link
          >|
          <router-link class="nav" to="/privacy" tag="button" type="button"
            >Privacy Policy</router-link
          >
        </div>
      </div>
      <div class="map_c">
        <iframe
          :src="mapSrc"
          v-if="mapSrc"
          style="border: 0"
          loading="lazy"
          referrerpolicy="origin"
        ></iframe>
      </div>
    </div>

    <footer>© 2024 Sana Illam</footer>

    <div class="splash" v-if="store.loading"><LoadingRings />Loading...</div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const store = useStore();
const venueName = computed(() => store.user && store.user.venue_name);

//const testMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196.98190453927515!2d144.96527401045867!3d-37.82025007947482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b3da012771%3A0xed0bbc69f9682065!2sYASSAS%20THE%20GREEK%20WAY%20-%20SOUTHBANK!5e0!3m2!1sen!2sau!4v1689006767734!5m2!1sen!2sau";

const mapSrc = computed(() =>
  store.mapEmbedSource ? store.mapEmbedSource : ""
);

const address = computed(() => {
  return `${store.user?.venue_name}<BR> ${store.user?.address.replace(",", "<BR>") || ""}<BR> ${store.user?.city || ""}<br> ${
    store.user?.state || ""
  }, ${store.user?.postcode || ""}`;
});

const phone = computed(() => store.user?.phone || "");
const email = computed(() => store.user?.email || "");

function handleBooking() {
  router.push("/contact");
}
</script>

<style scoped>
.root {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  color: #505050;
}

nav {
  position: absolute;
  top: 0px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: white;
}

nav .link {
  font-size: large;
  font-weight: 600;
}

nav .social {
  margin: 0 20px;
}

.content {
  flex: 1 1 auto;
}

.splash {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-size: x-small;
  letter-spacing: 10px;
  color: #404040;
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.location {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  font-size: small;
  padding: 20px;
}

.root.contact .location {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex: 0;
  gap: 0;
  padding: 20px 0 0 0;
}

.location .contact_c {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #dfdfdf;
  color: #404040;
  padding: 20px 40px;
}

.root.contact .location .contact_c {
  width: 100%;
  padding: 20px 0px;
}

.contact_c .address {
  margin-top: auto;
}

.contact_c .phone {
  font-size: large;
}

.root.contact .contact_c .address {
  font-size: large;
}
.root.contact .contact_c .phone {
  font-size: x-large;
}

.contact_c a {
  color: #404040;
  text-decoration: none;
}

.contact_c .docs_c {
  margin-top: auto;
}

.map_c {
  width: 300px;
  min-height: 500px;
}

.root.contact .map_c {
  width: 100%;
}

.location iframe {
  height: 100%;
}

.root.contact .location iframe {
  height: 100%;
  width: 100%;
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link {
  align-self: center;
  text-decoration: none;
  font-size: small;
  font-weight: 400;
  color: white;
  margin-right: 10px;
}

.line {
}

.line::after {
  align-self: center;
  display: flex;
  content: "";
  height: 1px;
  width: 120px;
  background-image: linear-gradient(
    to right,
    transparent,
    #666 75%,
    transparent
  );
  margin: 20px auto;
}

.social svg {
  height: 20px;
  width: 20px;
}

footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #303030;
  color: white;
  font-size: x-small;
  padding: 10px 30px;
}

footer .link {
  text-decoration: none;
  font-size: x-small;
}

.watermark {
  position: fixed;
  bottom: 50%;
  right: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: x-small;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn 10s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  padding: 20px;
}
.watermark .watermark_text {
  font-size: x-large;
  font-weight: bold;
}

.watermark .fcp {
  color: white;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* TODO: Layout for mobile! */

@media screen and (max-width: 800px) {
  nav {
    gap: 0;
    align-items: center;
  }

  nav .link {
    font-size: small;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  nav {
    display: flex;
    flex-wrap: wrap;
  }

  .link.home {
    margin-left: auto;
  }

  .content {
  }

  .location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-wrap: nowrap;
    font-size: small;
    padding: 20px 0px 0px 0px;
  }

  .location .contact_c {
    width: 100%;
    padding: 20px 0px;
  }

  .location .map_c {
    width: 100%;
  }

  .location .map_c iframe {
    width: 100%;
  }

  button.book {
    width: 80px;
    height: 30px;
    padding: 3px 5px;
    font-size: x-small;
  }
}

@media screen and (max-width: 400px) {
  nav .social {
    display: none;
  }
}
</style>
