import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useStore = defineStore('main', () => {
  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)
  const splashText = ref<string>('')
  const user = ref<User | null>(null);
  const isPro = ref(true);
  const loading = ref(true);

  //images
  const images_other = ref<ImageSource[]>([]);
  const images_inside = ref<ImageSource[]>([]);
  const images_outside = ref<ImageSource[]>([]);
  const images_surroundings = ref<ImageSource[]>([]);
  const images_rooms = ref<ImageSource[]>([]);





  const mapEmbedSource = computed(() => {

    let place = user.value?.venue_name +
      (user.value?.address ? ", " + user.value.address : "") +
      (user.value?.city ? ", " + user.value.city : "") +
      (user.value?.state ? ", " + user.value.state : "") +
      (user.value?.postcode ? ", " + user.value.postcode : "");
    return `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(place)}`;
  })

  function setWindowSize() {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  }

  async function setUser() {
    console.log('setUser');
    let url = import.meta.env.VITE_API_HOST + '/user';
    try {
      const response = await fetch(url);
      console.log(response.status);
      if (response.ok) {
        let text = await response.text();
        console.log(`data: ${text}`);
        let data = JSON.parse(text);
        console.log(`data.id: ${data.id}`);
        if (data && data.id) user.value = data;
        loading.value = false;
      }
    } catch (e) {
      console.log(e);
    } 
  }


  async function setContainer() {
    // const response = await fetch('/api/container');
    // if (response.ok) {
    //   const data = await response.json();
    //   if(data && data.id) user.value = data;
    //   loading.value = false;
    // }

    const other = [
      {
        src: "https://assets.sanaillam.in/other/lake.webp",
        thumbnail: "https://assets.sanaillam.in/other/lake.webp",
        alt: "lake",

      }
    ];


    const inside = [
      {
        src: "https://assets.sanaillam.in/inside/1.webp",
        thumbnail: "https://assets.sanaillam.in/inside/1.webp",
        alt: "Image 1",
      },
      {
        src: "https://assets.sanaillam.in/inside/2.webp",
        thumbnail: "https://assets.sanaillam.in/inside/2.webp",
        alt: "Image 2",
      },
      {
        src: "https://assets.sanaillam.in/inside/3.webp",
        thumbnail: "https://assets.sanaillam.in/inside/3.webp",
        alt: "Image 3",
      },
      {
        src: "https://assets.sanaillam.in/inside/4.webp",
        thumbnail: "https://assets.sanaillam.in/inside/4.webp",
        alt: "Image 4",
      },
      {
        src: "https://assets.sanaillam.in/inside/5.webp",
        thumbnail: "https://assets.sanaillam.in/inside/5.webp",
        alt: "Image 5",
      },
      {
        src: "https://assets.sanaillam.in/inside/6.webp",
        thumbnail: "https://assets.sanaillam.in/inside/6.webp",
        alt: "Image 6",
      },
    ];



    const outside = [
      {
        src: "https://assets.sanaillam.in/outside/1.webp",
        thumbnail: "https://assets.sanaillam.in/outside/1.webp",
        alt: "Image 1",
      },
      {
        src: "https://assets.sanaillam.in/outside/2.webp",
        thumbnail: "https://assets.sanaillam.in/outside/2.webp",
        alt: "Image 2",
      },
      {
        src: "https://assets.sanaillam.in/outside/3.webp",
        thumbnail: "https://assets.sanaillam.in/outside/3.webp",
        alt: "Image 3",
      },
      {
        src: "https://assets.sanaillam.in/outside/4.webp",
        thumbnail: "https://assets.sanaillam.in/outside/4.webp",
        alt: "Image 4",
      },
      {
        src: "https://assets.sanaillam.in/outside/5.webp",
        thumbnail: "https://assets.sanaillam.in/outside/5.webp",
        alt: "Image 5",
      }
    ];

    const surroundings = [
      {
        src: "https://assets.sanaillam.in/surroundings/1.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/1.webp",
        alt: "Image 1",
      },
      {
        src: "https://assets.sanaillam.in/surroundings/2.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/2.webp",
        alt: "Image 2",
      },
      {
        src: "https://assets.sanaillam.in/surroundings/3.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/3.webp",
        alt: "Image 3",
      },
      {
        src: "https://assets.sanaillam.in/surroundings/4.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/4.webp",
        alt: "Image 4",
      },
      {
        src: "https://assets.sanaillam.in/surroundings/5.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/5.webp",
        alt: "Image 5",
      },
      {
        src: "https://assets.sanaillam.in/surroundings/6.webp",
        thumbnail: "https://assets.sanaillam.in/surroundings/6.webp",
        alt: "Image 6",
      },
    ];



    const rooms = [
      {
        src: "https://assets.sanaillam.in/rooms/1.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/1.webp",
        alt: "Image 1",
      },
      {
        src: "https://assets.sanaillam.in/rooms/2.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/2.webp",
        alt: "Image 2",
      },
      {
        src: "https://assets.sanaillam.in/rooms/3.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/3.webp",
        alt: "Image 3",
      },
      {
        src: "https://assets.sanaillam.in/rooms/4.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/4.webp",
        alt: "Image 4",
      },
      {
        src: "https://assets.sanaillam.in/rooms/5.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/5.webp",
        alt: "Image 5",
      },
      {
        src: "https://assets.sanaillam.in/rooms/6.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/6.webp",
        alt: "Image 6",
      },
      {
        src: "https://assets.sanaillam.in/rooms/7.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/7.webp",
        alt: "Image 7",
      },
      {
        src: "https://assets.sanaillam.in/rooms/8.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/8.webp",
        alt: "Image 8",
      },
      {
        src: "https://assets.sanaillam.in/rooms/9.webp",
        thumbnail: "https://assets.sanaillam.in/rooms/9.webp",
        alt: "Image 9",
      },
    ];

    images_other.value = other;
    images_inside.value = inside;
    images_outside.value = outside;
    images_surroundings.value = surroundings;
    images_rooms.value = rooms;
  }



  return {
    windowWidth,
    windowHeight,
    splashText,
    user,
    isPro,
    loading,
    //images
    images_other,
    images_inside,
    images_outside,
    images_surroundings,
    images_rooms,

    //computes
    mapEmbedSource,



    //actions
    setWindowSize,
    setUser,
    setContainer
  }
})
